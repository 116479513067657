/* eslint-disable react/no-array-index-key */
import React from 'react';
import Link from 'next/link';
import VisuallyHidden from '@reach/visually-hidden';

import FaceBook from '@assets/images/svg/fb-logo.svg';
import Instagram from '@assets/images/svg/instagram-logo.svg';
import Smiley from '@assets/images/svg/smiley.svg';
import { FooterComponentProps } from '@models/common/footer';
import useStaticPageSegment from '@services/analytics/use-analytics';
import { chunk } from '@utils/common';

import { footerItems } from './footer-items';
import {
  FooterInner,
  FooterLargeWrapper,
  FooterListWrap,
  FooterLogoWrapper,
  FooterSocialMediaWrapper,
  FooterWrap,
  LinksDivider,
} from './footer.styled';

const Footer: React.FunctionComponent<FooterComponentProps> = ({
  showItems,
}) => {
  const { trackStaticPageEvents } = useStaticPageSegment();

  const fireTrackEvent = (pageTitle: string | undefined) => () => {
    if (pageTitle) {
      trackStaticPageEvents(pageTitle, {}, 'track');
      if (pageTitle === 'Footer - Help') {
        trackStaticPageEvents('Help Center');
      }
    }
  };

  const renderFooterItems = () => {
    const itemChunks = chunk(footerItems, 5);

    return itemChunks.map((itemChunk, index) => (
      <LinksDivider key={index}>
        {itemChunk.map((item, _index) => (
          <div key={_index}>
            {(() => {
              if (item.href) {
                const anchorProps = {
                  role: 'button',
                  tabIndex: 0,
                  onKeyPress: fireTrackEvent(item.segmentTitle),
                  onClick: fireTrackEvent(item.segmentTitle),
                };

                if (item.absolute) {
                  return (
                    <a
                      {...anchorProps}
                      href={item.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.label}
                    </a>
                  );
                }

                return (
                  <Link href={item.href as string}>
                    <a {...anchorProps}>{item.label}</a>
                  </Link>
                );
              }

              return <a>{item.label}</a>;
            })()}
          </div>
        ))}
      </LinksDivider>
    ));
  };

  if (!showItems) {
    return (
      <FooterWrap>
        <FooterInner>
          <Smiley css={{ margin: '0 auto', width: 56 }} />
        </FooterInner>
      </FooterWrap>
    );
  }

  return (
    <FooterWrap>
      <FooterLargeWrapper>
        <FooterLogoWrapper>
          <Smiley />
        </FooterLogoWrapper>
        <FooterSocialMediaWrapper>
          <a
            href="https://www.instagram.com/okayhumans/"
            target="_blank"
            rel="noreferrer"
            onClick={fireTrackEvent('Footer - Instagram')}
          >
            <>
              <VisuallyHidden>Go to Instagram page</VisuallyHidden>
              <Instagram />
            </>
          </a>
          <a
            href="https://facebook.com/okayhumans"
            target="_blank"
            rel="noreferrer"
            onClick={fireTrackEvent('Footer - Facebook')}
          >
            <>
              <VisuallyHidden>Go to Facebook page</VisuallyHidden>
              <FaceBook />
            </>
          </a>
        </FooterSocialMediaWrapper>
        <FooterListWrap>{renderFooterItems()}</FooterListWrap>
      </FooterLargeWrapper>
    </FooterWrap>
  );
};

export default Footer;
