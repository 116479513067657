import styled from '@emotion/styled';

import { small } from '@styles/media-queries';

export const DesktopOnly = styled.div({
  display: 'block',
  [small]: {
    display: 'none',
  },
});
