import React from 'react';
import Head from 'next/head';

import BrandLogo from '@assets/images/oh-logo.png';
import BrandImage from '@assets/images/OKH_ReferralLink_GIF.gif';
import { MetaTagProps } from '@models/common/meta-tag';

const MetaTag: React.FunctionComponent<MetaTagProps> = ({
  titleSuffix,
  description: _description,
  children,
}) => {
  const description =
    _description ||
    'Okay Humans is a new talk therapy / counseling experience focusing on emotional and mental wellness through easy to book sessions with our therapists.';

  return (
    <Head>
      <meta charSet="utf-8" />
      <title>Okay Humans | {titleSuffix}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0," />

      {/* <!-- Search Engine --> */}
      <meta
        name="keywords"
        content="therapy, counseling near me, therapist, feeling overwhelmed, talkspace, counseling, mental health, mental wellness"
      />
      <meta name="description" content={description} />
      <meta name="image" content={BrandImage} />

      {/* <!-- Schema.org for Google --> */}
      <meta itemProp="name" content={`Okay Humans | ${titleSuffix}`} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={BrandImage} />

      {/* <!-- Twitter --> */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={`Okay Humans | ${titleSuffix}`} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="okay_humans" />
      <meta name="twitter:creator" content="okay_humans" />
      <meta name="twitter:image:src" content={BrandImage} />

      {/* <!-- Open Graph general (Facebook, Pinterest & Google+) --> */}
      <meta property="og:title" content={`Okay Humans | ${titleSuffix}`} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="video.other" />
      <meta property="og:image" content={BrandImage} />
      <meta property="og:url" content="https://okayhumans.com/" />
      <meta property="og:site_property" content="Okay Humans" />
      <meta property="og:locale" content="en_us" />
      <meta property="fb:app_id" content="926513121089444" />
      {/* <meta property="og:type" content="website" /> */}
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Corporation',
            name: 'Okay Humans',
            alternateName: 'The Future of Mental Wellness.',
            url: 'https://okayhumans.com/',
            logo: BrandLogo,
            sameAs: [
              'https://instagram.com/okay_humans',
              'https://twitter.com/okay_humans',
              'https://facebook.com/okayhumanstherapy',
              'https://okayhumans.com',
              'https://www.youtube.com/channel/okayhumans',
            ],
          }),
        }}
      />
      {children}
    </Head>
  );
};

export default MetaTag;
