import styled from '@emotion/styled';

import * as colors from '@styles/colors';
import { small } from '@styles/media-queries';
import {
  fonts,
  fontSize,
  maxWidth,
  mediumWidth,
  textDecorations,
  textTransform,
} from '@styles/utils';

export const FooterWrap = styled.div({
  width: '100%',
  padding: 40,
  fontSize: 14,
  backgroundColor: colors.primary,
});

export const FooterInner = styled.div({
  width: '100%',
  maxWidth,
  margin: 'auto',
  textAlign: 'center',
  display: 'flex',
});

export const FooterLogoWrapper = styled.div({
  maxWidth: 78,
  width: '100%',
  margin: 'auto',
  textAlign: 'center',
  gridArea: 'logoSpace',
  [small]: {
    maxWidth: 47,
    margin: '0 auto',
    marginBottom: 0,
  },
});

export const FooterSocialMediaWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  textAlign: 'center',
  gridArea: 'socialSpace',
  [small]: {
    gridGap: 20,
    paddingTop: 0,
  },

  a: {
    marginLeft: 8,
    marginRight: 8,
  },
});

export const FooterLargeWrapper = styled.div({
  display: 'grid',
  gridGap: '0 120px',
  width: '100%',
  maxWidth: mediumWidth,
  margin: 'auto',
  paddingTop: '30px',
  paddingBottom: '30px',
  gridTemplateAreas: "'logoSpace listSpace' 'socialSpace listSpace'",

  [small]: {
    gridTemplateAreas: "'logoSpace' 'listSpace' 'socialSpace'",
    gridGap: '20px',
    padding: '20px',
  },
});

export const FooterListWrap = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: '0px 50px',
  lineHeight: 2,
  textAlign: 'left',
  gridArea: 'listSpace',

  a: [
    textTransform.uppercase,
    textDecorations.none,
    fontSize.fFifteen,
    fonts.bGRegular,
    {
      color: colors.white,
      lineHeight: '31px',
      letterSpacing: '1.2px',

      [small]: {
        lineHeight: '27px',
      },
    },
  ],

  [small]: {
    gridTemplateColumns: '1fr',
    textAlign: 'center',
  },
});

export const LinksDivider = styled.div({
  [small]: {
    borderTop: '1px solid #fff',
    padding: '20px 0px',
  },
});
