import { makeActionCreator } from '@actions/index';
import { Location } from '@models/location';
import { BookingFlows, SessionActionTypes } from '@models/session';
import { SlotFilter } from '@models/session/choose-therapist';
import { GuestInfoFormValue } from '@models/session/guest-info';
import { Price } from '@models/session/pricing';
import { TherapyReasons } from '@models/session/select-reason';
import { Slot } from '@models/slots';

const setBookingFlow = makeActionCreator<
  SessionActionTypes.SET_BOOKING_FLOW,
  BookingFlows
>(SessionActionTypes.SET_BOOKING_FLOW);

const setPricePreSelection = makeActionCreator<
  SessionActionTypes.SET_PRICE_PRE_SELECTION,
  boolean
>(SessionActionTypes.SET_PRICE_PRE_SELECTION);

const setSelectedLocation = makeActionCreator<
  SessionActionTypes.SET_SELECTED_LOCATION,
  Location
>(SessionActionTypes.SET_SELECTED_LOCATION);

const setIsVirtualSession = makeActionCreator<
  SessionActionTypes.SET_IS_VIRTUAL_SESSION,
  boolean
>(SessionActionTypes.SET_IS_VIRTUAL_SESSION);

const setReasonsForTherapy = makeActionCreator<
  SessionActionTypes.SET_REASONS_FOR_THERAPY,
  TherapyReasons
>(SessionActionTypes.SET_REASONS_FOR_THERAPY);

const setAccompanyingGuestInfo = makeActionCreator<
  SessionActionTypes.SET_ACCOMPANYING_GUEST_INFO,
  GuestInfoFormValue
>(SessionActionTypes.SET_ACCOMPANYING_GUEST_INFO);

const clearAccompanyingGuestInfo = makeActionCreator<
  SessionActionTypes.CLEAR_ACCOMPANYING_GUEST_INFO,
  undefined
>(SessionActionTypes.CLEAR_ACCOMPANYING_GUEST_INFO);

const setIsSlotFiltersPristine = makeActionCreator<
  SessionActionTypes.SET_SLOT_FILTERS_PRISTINE,
  boolean
>(SessionActionTypes.SET_SLOT_FILTERS_PRISTINE);

const setIsTherapistFilterPristine = makeActionCreator<
  SessionActionTypes.SET_THERAPIST_FILTER_PRISTINE,
  boolean
>(SessionActionTypes.SET_THERAPIST_FILTER_PRISTINE);

const setSlotFilters = makeActionCreator<
  SessionActionTypes.SET_SLOT_FILTERS,
  SlotFilter
>(SessionActionTypes.SET_SLOT_FILTERS);

const clearSlotFilters = makeActionCreator<
  SessionActionTypes.CLEAR_SLOT_FILTERS,
  undefined
>(SessionActionTypes.CLEAR_SLOT_FILTERS);

const setSelectedSlot = makeActionCreator<
  SessionActionTypes.SET_SELECT_SLOT,
  Slot
>(SessionActionTypes.SET_SELECT_SLOT);

const setSelectedPrice = makeActionCreator<
  SessionActionTypes.SET_SELECTED_PRICE,
  Price
>(SessionActionTypes.SET_SELECTED_PRICE);

const reset = makeActionCreator<SessionActionTypes.RESET, undefined>(
  SessionActionTypes.RESET,
);

export {
  setSelectedLocation,
  setIsVirtualSession,
  setPricePreSelection,
  setReasonsForTherapy,
  setAccompanyingGuestInfo,
  clearAccompanyingGuestInfo,
  setSlotFilters,
  setIsSlotFiltersPristine,
  setIsTherapistFilterPristine,
  clearSlotFilters,
  setSelectedSlot,
  setBookingFlow,
  setSelectedPrice,
  reset,
};
