export type SegmentEventType = 'page' | 'track';

const analytics = (
  page: string,
  opts: Record<string, any>,
  event: SegmentEventType,
): void => {
  if (event === 'page') {
    window?.analytics?.page(page, opts);
  } else {
    window?.analytics?.track(page, opts);
  }
};

export { analytics };
