import styled from '@emotion/styled';

import { maxWidth, maxWidthPageContent, mediumWidth } from '@styles/utils';

export const PageWrapper = styled.div({
  margin: '0 auto',
  padding: '30px 20px 30px',
  maxWidth,
  width: '100%',
});

export const MainWrapper = styled.div({
  minHeight: 'calc(100vh - 70px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  // overflowX: 'hidden',
});

export const fullWidth = {
  margin: '0 auto',
  padding: '0',
  maxWidth: 'none',
  width: '100%',
};

export const FullWidth = styled.div({
  margin: '0 auto',
  padding: '0',
  maxWidth: 'none',
  width: '100%',
});

export const MediumWidthWrapper = styled.div({
  margin: '0 auto',
  maxWidth: mediumWidth,
  width: '100%',
});

export const PageContentWrapper = styled.div({
  margin: '0 auto',
  maxWidth: maxWidthPageContent,
  width: '100%',
});

export const HideOverFlowX = styled.div({
  overflowX: 'hidden',
});
