import React from 'react';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';

import { ButtonProps } from '@models/common/button';
import * as colors from '@styles/colors';

const Loader: React.ComponentType = dynamic(() =>
  import('@components/shared/loader').then(mod => mod.Loader),
);

const buttonVariants: Record<string, any> = {
  primary: {
    background: colors.pink,
    color: colors.primary,
    textDecoration: 'none',
  },
  secondary: {
    background: colors.lightPink,
    color: colors.text,
    border: '1px solid rgba(0,0,0,0.15)', // need to make color in variable
  },
  ghost: {
    background: 'transparent',
    textAlign: 'left',
    padding: '0',
    flex: 'none',
    fontSize: '14px',
    textDecoration: 'underline',
    lineHeight: '23px',
  },
  blue: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
};

const StyledButton = styled.button<ButtonProps>(
  {
    padding: '8px 20px',
    fontSize: '18px',
    fontFamily: 'brandon-grotesque',
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '100%',
    borderRadius: '9px',
    border: 0,
    textTransform: 'uppercase',
    letterSpacing: '1.66px',
    outline: 'none',
    color: colors.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '40px',
  },
  ({ disabled = false, variant = 'primary' }) =>
    disabled && variant !== 'ghost'
      ? { background: colors.gray, color: colors.gray40 }
      : buttonVariants[variant],
);

const Button: React.FunctionComponent<ButtonProps> = ({
  loading,
  children,
  ...rest
}) => (
  <StyledButton {...rest}>
    {children}
    {loading ? <Loader css={{ marginLeft: 5 }} /> : null}
  </StyledButton>
);

export { Button };
