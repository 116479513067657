import { makeActionCreator } from '@actions/index';
import { GiftActionTypes } from '@models/gift';
import { GiftDeliveryForm } from '@models/gift/gift-delivery';
import { GiftType } from '@models/gift/gift-type';

const setSelectedGiftType = makeActionCreator<
  GiftActionTypes.SET_SELECTED_GIFT_TYPE,
  GiftType
>(GiftActionTypes.SET_SELECTED_GIFT_TYPE);

const setSelectedGiftDuration = makeActionCreator<
  GiftActionTypes.SET_SELECTED_GIFT_DURATION,
  number | null
>(GiftActionTypes.SET_SELECTED_GIFT_DURATION);

const setGiftDeliveryInfo = makeActionCreator<
  GiftActionTypes.SET_GIFT_DELIVERY_INFO,
  GiftDeliveryForm
>(GiftActionTypes.SET_GIFT_DELIVERY_INFO);

const setGiftPaymentMethodId = makeActionCreator<
  GiftActionTypes.SET_PAYMENT_METHOD_ID,
  string
>(GiftActionTypes.SET_PAYMENT_METHOD_ID);

const reset = makeActionCreator<GiftActionTypes.RESET, undefined>(
  GiftActionTypes.RESET,
);

export {
  setSelectedGiftType,
  setSelectedGiftDuration,
  setGiftDeliveryInfo,
  setGiftPaymentMethodId,
  reset,
};
