import React from 'react';
import VisuallyHidden from '@reach/visually-hidden';

import LogoWhite from '@assets/images/svg/oh-white.svg';
import { IconButton } from '@components/shared/icon';
import { BackButtonProps } from '@models/common/button';

const Logo: React.FunctionComponent<BackButtonProps> = ({
  handleClick,
  ...rest
}) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleClick();
  };

  return (
    <IconButton
      className="logo"
      css={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 12,
      }}
      type="button"
      onClick={onClick}
      {...rest}
    >
      <VisuallyHidden>Logo</VisuallyHidden>
      <LogoWhite />
    </IconButton>
  );
};

export default Logo;
