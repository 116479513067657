import styled from '@emotion/styled';

import { ButtonProps } from '@models/common/button';

const IconButton = styled.button<ButtonProps>({
  color: 'white',
  background: 'transparent',
  cursor: 'pointer',
  border: 'none',
  fontSize: '32px',
  lineHeight: '24px',
  outline: 'none',
  fill: 'white',
  verticalAlign: 'middle',
});

const TooltipIcon = styled(IconButton)({
  color: 'black',
  fontSize: '16px',
  verticalAlign: 'middle',
  marginLeft: '10px',
  lineHeight: 'normal',
});

export { IconButton, TooltipIcon };
