import React from 'react';

import { analytics, SegmentEventType } from '.';

const useStaticPageSegment = () => {
  const trackStaticPageEvents = React.useCallback(
    (
      pageTitle: string,
      additionalFields: Record<string, any> = {},
      event: SegmentEventType = 'page',
    ) => {
      const fields = { ...additionalFields };
      analytics(pageTitle, { ...fields }, event);
    },
    [],
  );

  return { trackStaticPageEvents };
};

export default useStaticPageSegment;
