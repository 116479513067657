import React from 'react';

import Footer from '@components/footer';
import Header from '@components/header';
import {
  fullWidth,
  MainWrapper,
  PageWrapper,
} from '@components/layout/layout.styled';
import { LayoutProps } from '@models/common/layout';

const Layout: React.FunctionComponent<LayoutProps> = ({
  headerProps,
  footerProps = { showItems: false },
  children,
  fullWidthLayout = false,
}) => (
  <>
    <Header {...headerProps} />
    <MainWrapper>
      <PageWrapper css={fullWidthLayout ? fullWidth : {}}>
        {children}
      </PageWrapper>
      <Footer {...footerProps} />
    </MainWrapper>
  </>
);

export default Layout;
