import styled from '@emotion/styled';

import * as colors from '@styles/colors';
import { small } from '@styles/media-queries';
import { colorBarDesktop, colorBarMob, maxWidth } from '@styles/utils';

export const HeaderRoot = styled.div<{ headerBorder: boolean }>(
  {
    width: '100%',
    minHeight: '70px',
    backgroundColor: colors.primary,
    position: 'fixed',
    top: '0',
    left: '0',
    color: 'white',
    display: 'flex',
    zIndex: 10,
  },
  ({ headerBorder = true }) =>
    headerBorder
      ? {
          ':before': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: '12px',
            left: '0',
            bottom: '-8px',
            background: colorBarDesktop,

            [small]: {
              height: 8,
              background: colorBarMob,
            },
          },
        }
      : undefined,
);

export const HeaderItems = styled.div({
  width: '100%',
  maxWidth,
  margin: '0 auto',
  padding: '0 20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const RightSecWrap = styled.div({
  zIndex: 11,

  [small]: {
    position: 'revert',
  },
});
