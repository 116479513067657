import { FooterItem } from '@models/common/footer';

export const footerItems: FooterItem[] = [
  {
    label: 'How It Works',
    href: '/how-it-works',
    segmentTitle: 'Footer - How it Works',
  },
  {
    label: 'Meet Our Therapists',
    href: '/therapists',
    segmentTitle: 'Footer - Meet our Therapists',
  },
  {
    label: 'Pricing',
    href: '/pricing',
    segmentTitle: 'Footer - Pricing',
  },
  {
    label: 'Press',
    href: 'https://press.okayhumans.com',
    segmentTitle: 'Footer - Press',
    absolute: true,
  },
  {
    label: 'Give a Gift',
    href: '/gift-cards',
    segmentTitle: 'Footer - Give a Gift',
  },
  {
    label: 'About Us',
    href: '/about',
    segmentTitle: 'Footer - About Us',
  },
  {
    label: 'Careers',
    href: '/careers',
    segmentTitle: 'Footer - Careers',
  },
  {
    label: 'Locations',
    href: '/locations',
    segmentTitle: 'Footer - Locations',
  },
  {
    label: 'Franchise',
    href: '/franchise',
    segmentTitle: 'Footer - Franchise',
  },
  {
    label: 'Philanthropy',
    href: '/philanthropy',
  },
  {
    label: 'Help',
    href: 'https://help.okayhumans.com',
    segmentTitle: 'Footer - Help',
    absolute: true,
  },
  {
    label: '1-855-651-1580',
    href: 'tel:+18556511580',
  },
  {
    label: 'Terms of Use',
    href: '/terms-of-use',
  },
  {
    label: 'Privacy Policy',
    href: '/privacy-policy',
  },
];
