import React from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { reset as giftReset } from '@actions/gift';
import { reset } from '@actions/session';
import {
  HeaderItems,
  HeaderRoot,
  RightSecWrap,
} from '@components/header/header.styled';
import { Button } from '@components/shared/button';
import { DesktopOnly } from '@components/shared/desktop-only';
import Logo from '@components/shared/logo-button';
import { useAuth } from '@context/auth';
import { useGiftDispatch } from '@context/gift';
import { useSessionDispatch } from '@context/session';
import { HeaderProps } from '@models/common/header';
import useStaticPageSegment from '@services/analytics/use-analytics';
import * as colors from '@styles/colors';
import { letterSpacing, textAlignments } from '@styles/utils';

const MainMenu = dynamic(() => import('@components/main-menu'));
const BackButton = dynamic(() => import('@components/shared/back-button'));
const HeaderCancelButton = dynamic(() =>
  import('@components/header/cancel-button'),
);

const Header: React.FunctionComponent<HeaderProps> = ({
  back,
  menu,
  bookLink,
  cancel,
  headerBorder = true,
  onBackClick,
  onCancelClick,
  cancelPopup,
}) => {
  const router = useRouter();
  const sessionDispatch = useSessionDispatch();
  const giftDispatch = useGiftDispatch();
  const { user } = useAuth();
  const { trackStaticPageEvents } = useStaticPageSegment();

  const clearState = () => {
    sessionDispatch(reset(undefined));
    giftDispatch(giftReset(undefined));
  };

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    } else router.back();
  };

  const handleCancelClick = () => {
    if (onCancelClick) {
      onCancelClick();
    } else if (user) {
      router.replace('/my-sessions')?.then(() => {
        clearState();
      });
    } else {
      router.replace('/')?.then(() => {
        clearState();
      });
    }
  };

  const handleLogoClick = () => {
    router.push('/');
  };

  const fireBookSessionClickedEvent = () => {
    trackStaticPageEvents('Header - Book a Session', {}, 'track');
  };

  const renderLeftSection = () => (
    <>
      <div>
        {back ? (
          <div>
            <BackButton handleClick={handleBackClick} />
          </div>
        ) : null}
        {bookLink ? (
          <DesktopOnly>
            <Link href="/book-session/locations" passHref>
              <Button
                key={0}
                variant="ghost"
                type="button"
                as="a"
                aria-label="Book a Session"
                css={[
                  textAlignments.textCenter,
                  letterSpacing.medium,
                  {
                    color: colors.white,
                    justifyContent: 'flex-start',
                    fontSize: 18,
                    textUnderlineOffset: '4px',
                    textDecorationThickness: '0.15em',
                  },
                ]}
                onClick={fireBookSessionClickedEvent}
              >
                Book a Session
              </Button>
            </Link>
          </DesktopOnly>
        ) : null}
      </div>
    </>
  );
  const renderCenterSection = () => (
    <Logo data-testid="logo" handleClick={handleLogoClick} />
  );

  const renderRightSection = () => (
    <RightSecWrap key={2}>
      {menu ? <MainMenu /> : null}
      {cancel ? (
        <HeaderCancelButton
          handleCancelClick={handleCancelClick}
          cancelPopup={cancelPopup}
        />
      ) : null}
    </RightSecWrap>
  );

  return (
    <HeaderRoot headerBorder={headerBorder}>
      <HeaderItems>
        {renderLeftSection()}
        {renderCenterSection()}
        {renderRightSection()}
      </HeaderItems>
    </HeaderRoot>
  );
};

Header.defaultProps = {
  menu: false,
  bookLink: false,
  back: true,
  cancel: true,
};

export default Header;
